// App

/*============================================================== 
 For all pages 
 ============================================================== */
body {
  background: transparent;
}

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
}

.page-wrapper>.container-fluid {
  padding: 20px;
  // min-height: calc(100vh - 134px);
  min-height: 91vh;
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
  background: $white;
}

/*******************
 Stickey inner-left-part
*******************/

.main-part {
  display: flex;
  height: calc(100vh - 174px);
  align-items: strech;
}

$left-part-width: 260px;

.left-part {
  width: $left-part-width;
  border-right: 1px solid $border-color;
  flex-shrink: 0;

  .show-left-part {
    position: absolute;
    top: 25%;
    right: -41px;
  }
}

.right-part {
  width: 100%;
  overflow: auto;

  .dropdown-toggle::after {
    display: none;
  }
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout='vertical'] {

  /*Vertical*/
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }

  /*sidebar type*/
  &[data-sidebartype='full'] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }

  &[data-sidebartype='iconbar'] {
    .hide-menu {
      margin-right: 0;
      max-width: 150px;
      display: block;
    }

    .logo-icon {
      margin: 0 auto;
    }

    .logo-text {
      display: none;
    }

    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    .left-sidebar,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          display: block;
          text-align: center;

          i,
          i-feather {
            display: block;
            width: auto;
            visibility: visible;
          }
        }
      }
    }
  }

  &[data-sidebartype='overlay'] {
    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    .left-sidebar {
      left: -$sidebar-width-full;
    }

    .page-wrapper {
      margin-left: 0px;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  /*Sidebar position*/
  &[data-sidebar-position='fixed'] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }

    .left-sidebar {
      position: fixed;
    }
  }

  /*Header position*/
  &[data-header-position='fixed'] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .page-wrapper {
      padding-top: $topbar-height;
    }
  }

  /*Boxed Layout*/
  &[data-boxed-layout='boxed'] {
    max-width: $boxed-width;
    margin: 0 auto;
    position: relative;
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  &[data-boxed-layout='boxed'][data-header-position='fixed'] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(lg) {
  #main-wrapper[data-layout='vertical'] {

    /*Vertical*/
    &[data-sidebartype='full'] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }

    /*Sidebar position*/
    &[data-sidebar-position='fixed'][data-sidebartype='full'],
    &[data-sidebar-position='fixed'][data-sidebartype='overlay'] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    &[data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebar-position='fixed'][data-sidebartype='iconbar'] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }

    /*Vertical with mini-sidebar*/
    &[data-sidebartype='mini-sidebar'] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }
        }
      }
    }

    /*Fixed sidebar with minisidebar*/
    &[data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebartype='mini-sidebar'] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {

        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          margin-left: 5px;
          padding: 12px 17px;

          i {
            display: inline-block;
          }
        }

        .first-level {
          .sidebar-item .sidebar-link i {
            visibility: visible;
          }
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {

            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
              padding: 12px 23px;

              i {
                display: none;
              }
            }

            .first-level {
              .sidebar-item .sidebar-link i {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  #main-wrapper {
    &[data-sidebartype='mini-sidebar'] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }

    &[data-layout='vertical'][data-sidebar-position='fixed'] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }

    /*Header position*/
    &[data-layout='vertical'][data-header-position='fixed'][data-sidebar-position='fixed'] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }

  .left-part {
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    position: absolute;
    height: calc(100vh - 175px);
    transition: 0.1s ease-in;

    &.show-panel {
      left: 0px;
    }
  }

  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;

    &.show-panel {
      right: 0px;
    }
  }

  .right-part {
    width: 100%;
    margin-left: 0px;
  }
}