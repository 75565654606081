/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

// $font-family-sans-serif: 'Helvetica Neue' !important;

$custom-file-text: (
  en: 'Browse',
  es: 'Elegir',
);

.required:after {
  content: ' *';
  color: red;
}

//(Required file)
@import 'variable';

//Theme colors
@import 'theme-colors/dark-theme';

// Import Bootstrap source files (Required file)
@import '../../../node_modules/bootstrap/scss/bootstrap';

//This is for the components (Required file)
@import 'components';

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import 'pages';

//This is for the widgets (Required file)
@import 'widgets/widgets';

//This is for the horizontal version if you are not using horizontal version you can remove this
@import 'horizontal/horizontal';

//This is for the responsive (Required file)
@import 'responsive';

//In This scss you can write your scss
@import 'custom';

//This is for the icons (Required file)
@import 'icons/font-awesome/css/fontawesome-all.css';
@import 'icons/simple-line-icons/css/simple-line-icons.css';
@import 'icons/weather-icons/css/weather-icons.min.css';
@import 'icons/themify-icons/themify-icons.css';
@import 'icons/flag-icon-css/flag-icon.min.css';
@import 'icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'icons/crypto-icons/cryptocoins.css';

// this is for the perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/** Card Menu **/

.menu-card {
  background-color: transparent;
}

/**End card menu**/

/** Sidebar Menu   **/

.sidebar-nav {
  ul .sidebar-item {
    .sidebar-link {
      .sidebar-img {
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }
  }
}

/** End Sidebar Menu**/

.table td,
table td {
  vertical-align: middle;
}

.table thead th {
  background: $blue-ny0 !important;
  color: white !important;
}

/**Button**/

[data-theme='dark'] .btn-primary {
  background-color: $blue-ny0 !important;
}

[data-theme='dark'] .btn-secondary {
  background-color: $red-ny0 !important;
}

/**End button**/

/** Paging **/

.page-link {
  color: $blue-light-ny0;
}

.page-link:hover {
  color: $blue-ny0;
}

/** End paging **/

/** Table **/
[data-theme='dark'] .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(210 220 225 / 10%);
}

/** End table **/

/** Starter Logo **/

.content-element {
  position: relative;
}

/** End starter logo**/

/** Login**/
.background-start {
  background: linear-gradient($blue-light-ny0, $blue-ny0) !important;
}

.btn-login {
  width: 80%;
  background: $blue-ny0;
  font-size: 14px !important;
  color: white !important;
}

/** End login**/

/**Error messges in light theme **/
.control-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: $amaranth-ny0;
}

/**End error messges in light theme**/

/**Error messages in theme dark**/
[data-theme='dark'] .control-error,
[data-theme='dark'] .required:after {
  color: $orange-ny0;
}

/**End error messages in theme dark**/

/**Border fields error theme dark**/
[data-theme='dark'] .ng-touched.ng-invalid {
  border-color: $orange-ny0 !important;
}

/**End border fields error theme dark**/

/**Border of fields multiselect error**/
.ng-multiselect-dropdown-error>div>div>span.dropdown-btn {
  border: 1px solid $red !important;
}

[data-theme='dark'] .ng-multiselect-dropdown-error>div>div>span.dropdown-btn {
  border: 1px solid $orange-ny0 !important;
}

/**End border of fields multiselect error**/

/**Background color form controls**/

span.selected-item {
  max-width: inherit !important;
}

[data-theme='dark'] .form-control,
[data-theme='dark'] .custom-file-label {
  background-color: $gray-600-ny0 !important;
  color: white;

  &:focus {
    color: white;
  }
}

[data-theme='dark'] span.dropdown-btn {
  background-color: $gray-600-ny0 !important;
}

[data-theme='dark'] option {
  background: $gray-ny0 !important;
  color: $gray-200 !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:before {
  background: $blue-ny0 !important;
  border: 2px solid $blue-ny0 !important;
}

.multiselect-item-checkbox input[type='checkbox']+div:before {
  border: 2px solid $blue-ny0 !important;
}

li.multiselect-item-checkbox:hover {
  background: $blue-ny0 !important;
}

.multiselect-dropdown .dropdown-list {
  box-shadow: none !important;
}

[data-theme='dark'] .multiselect-item-checkbox input[type='checkbox']+div {
  color: $gray-200 !important;
}

[data-theme='dark'] span.selected-item {
  border: $blue-ny0 !important;
  background: $blue-ny0 !important;
}

/**End background color form controls**/

/**Background color paging controls**/

[data-theme='dark'] .page-link,
[data-theme='dark'] .page-item.disabled .page-link {
  background-color: $gray-600-ny0 !important;
}

[data-theme='dark'] .custom-select {
  background-color: $gray-600-ny0 !important;
}

/**End background color paging controls**/

/**Color field tag for uploading files **/
[data-theme='dark'] .custom-file-label {
  color: $blue-gray-ny0 !important;
}

/**End color field tag for uploading files**/

/**Login design**/
.text-box {
  color: white !important;
  font-size: 14px !important;
  border: none !important;
  background: none !important;
  box-shadow: 18px 18px 0px -16px $dark-gray-ny0,
    -18px 18px 0px -16px $dark-gray-ny0;
  text-align: center;
}

.text-box::placeholder {
  color: white !important;
}

.login-error {
  box-shadow: 18px 18px 0px -16px $orange-ny0, -18px 18px 0px -16px $orange-ny0;
}

input.text-box+control-error>label.control-error {
  color: $orange-ny0 !important;
}

.tailor-error>.control-error {
  color: $orange-ny0;
}

/**End Login design**/

/** Calendar**/
[data-theme='dark'] button.calendar {
  &.btn-outline-primary {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
}

[data-theme='dark'] button.calendar>i.fa-calendar:before {
  color: $blue-gray-ny0;
}

button.calendar {
  &.btn-outline-primary {
    border-color: $gray-200 !important;
  }
}

/**End calendar**/

/** Icons in tables**/
[data-theme='dark'] .fa-edit:before,
[data-theme='dark'] .fa-user-plus:before,
[data-theme='dark'] .fa-car:before,
[data-theme='dark'] .fa-eye:before,
[data-theme='dark'] .fa-upload:before,
[data-theme='dark'] .fa-download:before,
[data-theme='dark'] .fa-copy:before,
[data-theme='dark'] .fa-file-pdf:before,
[data-theme='dark'] .fa-check:before {
  color: $white !important;
}

[data-theme='dark'] tspan {
  fill: rgb(255, 255, 255) !important;
}

.fa-eye-slash:before,
.fa-trash:before,
.fa-user-times:before,
.text-danger.fa-car:before,
.text-danger.fa-map-marker-alt:before {
  color: $red-icon-ny0 !important;
}

/**End  icons in tables**/

/** Tables **/
.table {
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
  }

  [data-theme='dark'] td {
    color: $white;
  }
}

/** end Tables **/

/** Remove default browser styles */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition-delay: 9999s;
  transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/** End Remove default browser styles */

/**Placeholder-mask**/
.placeholder-mask::-webkit-input-placeholder {
  /* WebKit browsers */
  color: $gray-700 !important;
}

.placeholder-mask:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $gray-700 !important;
}

.placeholder-mask::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $gray-700 !important;
}

.placeholder-mask:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: $gray-700 !important;
}

/** End placeholder-mask**/

/**Color pages title**/
.page-breadcrumb .page-title {
  color: $blue-light-ny0 !important;
  font-weight: 600;
}

/**End color pages title**/

/**Sidebar text **/
.sidebar-nav ul .sidebar-item .sidebar-link {
  opacity: 0.8;
}

/**End sidebar text **/

/**Card background**/
[data-theme='dark'] .card {
  background: $gray-ny0 !important;
}

/**End card background**/

/** Switch control **/
.custom-switch {
  cursor: pointer;

  .custom-control-input {
    cursor: pointer;
  }

  // &:checked {
  //   &.custom-switch-label {
  //     &::before {
  //       color: #fff;
  //       border-color: $blue !important;
  //       background-color: $blue !important;
  //     }

  //     &::after {
  //       top: 0.3rem;
  //     }
  //   }
  // }
  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $blue !important;
    background-color: $blue !important;
  }

  .custom-control-label::after {
    top: 0.3rem;
  }
}

/** End Switch control **/

/** Icon inside textbox **/

.icon-inside {
  background-color: $gray-600-ny0;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5467;
  text-align: center;
  white-space: nowrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  border-radius: 2px;
  margin-left: 0;
}

.icon-inside-noborder {
  background-color: none;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  white-space: nowrap;
  border: none;
  margin-left: 0;
  margin-left: -40px;
  z-index: 1000;
}

.textbox-icon-inside {
  border-right: none;
}

/** End Icon inside textbox **/
a {
  &.data-extra {
    color: $white;
    text-decoration: underline;

    &:hover {

      font-weight: bold;
      // color: #398bf7;
      color: inherit;
    }
  }
}

/** Slide checkbox styles **/
// .custom-switch:checked~.custom-switch-label::before {
//   color: #fff;
//   border-color: $blue !important;
//   background-color: $blue !important;
// }

// .custom-switch-label::after {
//   top: 0.3rem;
// }